/** ============================================================================
 * @base Globals
 * ========================================================================== */

 body {
     overflow-x: hidden;
 }


section {
    text-align: center;
    padding: 4em 1em;
    position: relative;
    
    @include desktop-up {
        padding: 6rem 4rem;
    }
}

.container-card-img {
    position: relative;
}

.disclaimer {
    position: absolute;
    right: 1.5%;
    bottom: 0.5%;
    font-size: 0.5em;
    color: $secondary-color;
}

.disclaimer-left {
    position: absolute;
    left: 4%;
    bottom: 0.5%;
    font-size: 0.5em;
    z-index: 99;
    color: $secondary-color;
}

.disclaimer-center {
    // position: absolute;
    // left: 50%;
    // bottom: 1%;
    font-size: 0.5em;
    z-index: 99;
    color: $secondary-color;
}

.secondary-bg {
    background: $secondary-color;
}

.tertiary-bg {
    background: $tertiary-color;

    p {
        color: $secondary-color;
    }
}

.section-title {
    &::before {
        content: url('../img/feather.png');
        display: block;
    }
    &::after {
        content: '';
        display: block;
        width: 2.5em;
        height: 1px;
        background: $primary-color;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin: 0.4em 0 1em;
        transition: all 0.3s ease;
    }
}

.section-link {
    border-bottom: 1px solid $primary-color;
    padding-bottom: 2px;
    position: relative;
    
    &::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        background: $primary-color;
        transform: rotate(45deg);
        position: absolute;
        top: 5px;
        right: -30px;
        transition: all 0.3s ease;
    }

    &::after {
        content: '\0025B6';
        display: inline-block;
        position: absolute;
        top: 6px;
        right: -26px;
        font-size: 0.5em;
        font-weight: normal;
        color: $secondary-color;
    }

    &:hover {

        &::before {
            transform: rotate(180deg);
        }
    }
}
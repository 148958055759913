/** ============================================================================
 * @base Mixins
 * ========================================================================== */

/* Responsive Helpers
/* -------------------------------------------------------------------------- */
$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;
$breakpoint-desktop-lg: 1200px;
$breakpoint-desktop-xlg: 1600px;

@mixin on-mobile-only {
  @media screen and (max-width: #{$breakpoint-mobile - 1}) { @content; }
}

@mixin mobile-up {
  @media screen and (min-width: $breakpoint-mobile) { @content; }
}

@mixin tablet-up {
  @media screen and (min-width: $breakpoint-tablet) { @content; }
}

@mixin desktop-up {
  @media screen and (min-width: $breakpoint-desktop) { @content; }
}

@mixin desktop-lg {
  @media screen and (min-width: $breakpoint-desktop-lg) { @content; }
}

@mixin desktop-xlg {
  @media screen and (min-width: $breakpoint-desktop-xlg) { @content; }
}

@mixin height-sm {
  @media screen and (max-height: $breakpoint-tablet) { @content; }
}


/* Page hero section
/* -------------------------------------------------------------------------- */
@mixin hero-section($imgsrc-sm, $imgsrc-lg) {
  background: url($imgsrc-sm) 15% 90% no-repeat; //fallback
  background: linear-gradient(to bottom, #0B1C2E 10%, transparent 45%), url($imgsrc-sm) 15% 90% no-repeat;
  background-size: cover;
  height: 100vh;

  @include desktop-up {
      background: url($imgsrc-lg) 15% 75% no-repeat; //fallback
      background: linear-gradient(to bottom, #0B1C2E 10%, transparent 40%), url($imgsrc-lg) 15% 75% no-repeat;
      background-size: initial;
  }
}
:root {
    font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $heading-font;
    color: $primary-color;
    letter-spacing: 1px;
}

h1 {
    font-size: 3rem;

    @include desktop-up {
        font-size: 4em;
    }
}

h2 {
    font-size: 2.2em;
    
    @include desktop-up {
        font-size: 3em;
    }
}

h3 {
    
    @include desktop-up {
        font-size: 2em;
    }
}

p {
    font-family: $body-font;
    color: black;
    letter-spacing: 0.03em;
}

// a {
//     color: $primary-color;
//     font-family: $secondary-font-family;
//     font-weight: bold;

//     &:hover {
//         text-decoration: none;
//         color: $primary-color;
//         font-weight: normal;
//     }
// }
/*---------------------
    Latest News
----------------------*/

.latest-news {
  background: #F0F0F0;
  p {
    font-size: 15px;
  }
  a.btn {
    font-family: $secondary-font-family;
    font-size: 15px;
    color: $secondary-color;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none !important;
    outline: none !important;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid $secondary-color !important;
    padding-bottom: 0.5px;
    border-radius: 0;
    &:hover, &:focus, &.active {
      text-decoration: none !important;
      outline: none !important;
      box-shadow: none;
    } 
  }
}

.container-latest-news {
  background-color: white;
  .row {
    padding: 2rem 0rem;
    @include tablet-up {
      padding: 3rem 0rem;
    }
  }
}

.date-news {
  font-family: $secondary-font-family;
  font-size: 16px;
  font-weight: 800;
  // color: $secondary-color !important;
  // letter-spacing: 1px;
}

.title-news {
  font-family: $secondary-font-family;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 800;
}

.img-latest-news {
  max-width: 500px;
  width: 100%;
  height: auto;
}

.container-info {
  margin-top: 1.5rem;
  @include tablet-up {
    margin-top:auto;
    margin-bottom: auto;
    padding-left: 2rem;
  }
  a {
    color: black;
  }
}

.viewMore[aria-expanded="false"]:before{
  content:"Read More";
}

.viewMore[aria-expanded="true"]:before{
  content:"Back";
}

/*--------------------
    Popup Swipe
--------------------*/

.photo-swipe {
  h1 {
    margin: 2em;
  }
  .pswp__caption__center {
    text-align: center;
  }
  figure {
    // display: inline-block;
    width: 33.333%;
    float: left;
  }
  img {
    width: 100%;
  }
  .spacer {
    height: 5em;
  }
}


.pswp__caption__center {
  text-align: center;
}

figure {
  // display: inline-block;
  width: 33.333%;
  float: left;
  img {
    width: 100%;
  }
}

.spacer {
  height: 5em;
}

.figure-link {
  position: relative !important;
}
.zoom-btn {
  position: absolute !important;
  top: 5px;
  right: 15px;
  background-color: #707070;
  border-radius: 30px;
  color: white;
  padding: 5px;
  font-size: 0.5rem !important;
  @include tablet-up {
    padding: 10px;
    font-size: 1rem !important;
  }
}

/*-----------------------
    Load More button
------------------------*/

.my-gallery {
  width: 100%;
  float: left;
}
.my-gallery img {
  width: 100%;
  height: auto;
}
.my-gallery figure {
  // display: block;
  // float: left;
  margin: 0 5px 5px 0;
  width: 150px;
}
.my-gallery figcaption {
  display: none;
}


/*------------------------
    Interior Furnishing
--------------------------*/

.quill--gallery {
  background: linear-gradient(to bottom, #005480 -50%, #002A40 120%);
  .header-top {
    background: linear-gradient(to bottom, #005480 -5%, #002A40 90%) !important;
  }
  h3 {
    color: $secondary-color;
    letter-spacing: 1px;
  }
  footer {
    background: transparent;
    p, a {
      color: white;
    }
  }
}

/*------------------------
    360 Virtual Tower
------------------------*/

.virtual-tower {
  background: linear-gradient(to bottom, #005480 -50%, #002A40 120%) !important;
}

.title-virtual-tower {
  // background: linear-gradient(to bottom, #005480 15%, #002A40 90%) !important;
  padding: 10rem 0rem 4rem 0rem;
}

.container--360-virtual-tower {
  background: url('../img/gallery/virtual-tower.jpg') no-repeat; //fallback
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 70vh;
}

.title-360 {
  color: white !important;
  margin-top: 1rem;
}

/*-------------------------
      Site Progress
-------------------------*/

.site-progress-caption {
  color: white;
  padding-top: 1rem;
  opacity: 0.8;
  margin-bottom: 0px;
}

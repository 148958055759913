.about-quill-group {
  background: linear-gradient(to bottom, #005480 -50%, #002A40 120%);
  .hero {
    background-image: url('../img/about-quill-group/hero-about-quill-group.jpg');
    background-size: cover;
    background-position: center;
    min-height: 50vh;
    height: auto;
  }
  .description {
    background-color: white;
  }
  h3 {
    color: $secondary-color;
  }
  p {
    padding: 5rem 0rem;
    margin: auto;
    @include tablet-up {
      width: 75%;
    }
    @include desktop-up {
      width: 70%;
    }
    @include desktop-lg {
      width: 63%;
    }
  }
  #bg-half {
    display: block;
    height: 100%;
    background-position: bottom, top;
    background-repeat: no-repeat;
    width: 100%;
    background-image: linear-gradient(to bottom, white 8%, #005480 -50%, #002A40 107%);
    @include desktop-up {
      background-image: linear-gradient(to bottom, white 21%, #005480 -50%, #002A40 107%);
    }
    img {
      max-width: 1200px;
      height: auto;
      width: 100%;
    }
  }
}

@media (max-width: 480px) { 
  .header-top span {
    display: block;
  }
}

.text-white {
  color: white;
}

/** ========================================================================= *\
 * @Layout: Navigation Menu
 * ========================================================================== */

 header {
    // position: fixed !important;
    top: -0.3em;
    right: 1em;

    width: 100%;
    z-index: 999 !important;
    @include desktop-up {
        position: relative !important;
        top: -0.3em;
        right: 0em;
    }
 }

.navbar-wrapper {
    background-color: #FFFFFF;
    .quill-logo-nav {
        width: 50px !important;
        margin-top: -8px;
        margin-right: 1.8rem !important;
        @include desktop-xlg {
          margin-right: 2.5rem !important;
       }
    }
    display: none;
    @include desktop-up {
        display: block;
    }
    .navbar {
      padding: 0rem;
    }
}

// .navdesktop-content {
//   .nav-menus .menu.dropdown {
//       padding: 0px 0px 30px 0px;
//     }
// }

.link-dropdown {
  padding-bottom: 30px !important;
}

// .navbar-brand {
//     @include desktop-xlg {
//         padding-left: 8em;
//     }
//   }

.nav-menus {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
}

.btn-register {
    text-transform: uppercase;
    font-family: $tertiary-font-family;
    font-size: 0.9em;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white !important;
    background-color: $secondary-color;
    padding: 10px 20px;
    border-radius: 20px;
    &:hover, &:focus, &.active {
        // color: black !important;
        color: $secondary-color !important;
        background-color: transparent;
        border: 1px solid $secondary-color !important;
        text-decoration: none;
        font-weight: bold;
        transition: all 0.15s ease-in-out;
    }
}

.navbar {
    .menu-main-menu-left-container {
        display: none;
    
        @include desktop-up {
            display: block;
            margin-left: auto;
        }
    }
    .menu-main-menu-right-container {
        display: none;
        
        @include desktop-up {
            display: block;
            margin-right: auto;
        }
    }
}



.nav-menu {
  ul {
    // padding: 1.2em;

    margin: 0 auto;
    padding-left: 20px;
    // max-width: 1132px !important;
    @include tablet-up {
        padding: 0.4em 0.2em;
    }
    @include desktop-lg {
        padding: 0.4em;
    }
  }
}

.nav-menu {
    list-style: none;
    margin: 0em;
    @include desktop-up {
      margin: 1em 2em;
    }
    @include desktop-lg {
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
    }

    li {
        display: inline !important;
        &:hover, &:focus, &.active {
            text-decoration: none;
            color: $secondary-color;
        }
        @include tablet-up {
            padding: 0em 0.6em 0em;
        }
        @include desktop-up {
            padding: 0em 0.6em 0em;
        }
        // @include desktop-lg {
        //     padding: 2em 1em 2em;
        // }
        @include desktop-xlg {
            padding: 0em 1.4em 0em;
         }
    }

    a {
        text-transform: uppercase;
        font-family: $secondary-font-family;
        letter-spacing: 1px;
        font-size: 13px;
        font-weight: 700;
        color: black;
        text-transform: uppercase;

        &:hover, &:focus, &.active {
            text-decoration: none;
            color: $secondary-color;
            font-weight: bold;
        }

        @include tablet-up {
            letter-spacing: 1px;
        }
        @include desktop-up {
            letter-spacing: 0rem;
        }
        @include desktop-lg {
            letter-spacing: 1px;
        }
    }
}

.dropdown {
    position: relative;
    display: inline-block;
  }
  

.dropdown-content {
    -webkit-box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.3) !important;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.3) !important;
    display: none;
    // position: absolute;
    background-color: white;
    // min-width: 160px;
    // box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    // z-index: 1;
    // left: 50%;
    // transform: translate(-50%);
    li {
        text-align: center;
        padding: 5px 0;
        list-style: none;
        margin: 0 12px;
        min-width: 225px;
    }
  }

.cn-lang {
	.dropdown-content li {
		min-width: 205px;
	}
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    &:hover, &.active {
        color: $secondary-color;
        font-weight: bold;
    }
  }

.dropdown-content a:hover {background-color: white;}

.dropdown:hover .dropdown-content {
    @include desktop-up {
            
        display: flex;
        position: absolute !important;
        top: 190% !important;
        padding: 0.3em 1000em !important; 
        // margin: 0 -1000em !important;
        z-index: 101 !important;
        
        transform: translateX(-50%);
        box-shadow: 
            inset 0px 11px 8px -10px #CCC,
            // inset 0px -11px 8px -10px #CCC; 
    }
    display: block;
}

.dropdown:hover .dropdown-content.first-dropdown {
  @include desktop-up {
    left: 234% !important;    
  }
}

.dropdown:hover .dropdown-content.first-dropdown-cn {
	@include desktop-up {
			left: 280% !important;    
	}
}

.dropdown:hover .dropdown-content.second-dropdown-cn {
	@include desktop-up {
		left: 85%;  
	}
}

.dropdown:hover .dropdown-content.second-dropdown {
  @include desktop-up {
    left: 85%;
  }
}

/*-------------------------
    Navigation on mobile
---------------------------*/

.navmobile-menu {
    li {
        list-style: none;
        a {
            font-family: "Century Gothic";
            letter-spacing: 1px;
            // font-size: 0.9em;
            color: black;
            text-transform: uppercase;
            &:hover, &:focus, &.active {
                text-decoration: none;
                color: $secondary-color;
                font-weight: bold;
            }
        }
    }
}

.dropdown-menu li:hover,
.dropdown-menu li a:hover,
.dropdown-menu a:focus,
ul li a.active,
.dropdown-menu a.active {
//   background-color: #E6EAEE;
  font-weight: 600;
  text-decoration: none;
}

.dropdown-menu {
	padding-left: 1.1rem;
	padding-bottom: 0px;
  position: static !important;
  border: none;
  border-radius: 0px;
  background-color: transparent;
  transform: translate3d(0px, -7px, 0px) !important;
//   transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    overflow: hidden;
}
.dropdown-toggle:after {
  border-top: 0rem solid !important;
}
.fa-caret-down {
  padding-left: 5px;
}
.nav-item .dropdwon {
  margin: 0px 10px;
}

.nav-link {
    padding: 0;
}

.hamburger-menu {
    position: fixed;
    top: -0.3em;
    right: 1em;

    width: 3em;
    height: 3em;
    border: 2px solid $primary-color;
    border-radius: 1em;
    margin: 2em 0.5em 0;
    background: $secondary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    // position: relative;
    margin-left: auto;
    transition: all 0.5s ease;
    z-index: 101;

    &:hover {
        cursor: pointer;
        background: $primary-color;
        transition: all 1s ease;

        span, span:before, span:after {
            background-color: $secondary-color;
        }
    }

    @include desktop-up {
        display: none;
    }

    span {
        width: 26px;
        height: 3px;
        margin-top: 1px;
        background: $primary-color;
        border-radius: 0.5em;
        transition: all 0.5s ease;

        &::before, &::after {
            content: '';
            width: 26px;
            height: 3px;
            background: $primary-color;
            border-radius: 0.5em;
            position: absolute;
            transition: all 0.5s ease;
        }

        &::before {
            top: 13px;
        }

        &::after {
            bottom: 12px;
        }
    }

    &.active {
        position: fixed;
        top: -0.3em;
        right: 1em;
        background: $primary-color;

        span {
            background: $primary-color;

            &::before {
                background: $secondary-color;
                top: unset;
                transform: rotate(45deg);
            }

            &::after {
                background: $secondary-color;
                bottom: unset;
                transform: rotate(-45deg);
            }
        }
    }
}

.navmobile-wrapper {
    background: $tertiary-color;
    border-right: 5px solid $primary-color;
    position: fixed;
    top: 0;
    left: -2.3em;
    z-index: 100;
    padding: 0.5em 1em;
    height: 100%;
    width: 0;
    overflow-x: hidden;
    transition: 0.5s;
    
    &.active {
        width: 100%;
        left: 0;
    }
    display: block;
    @include desktop-up {
        display: none;
    }
}

.navmobile-content {
    height: 95vh;
    width: 100%;
    position: relative;
}

.navmobile-menu {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .nav-menu {
        margin-bottom: unset;

        li {
            padding: 0.5em 0
        }

        a {
            font-size: 1.5em;
        }
    }
}

/*----------------
    Add sticky
-----------------*/

.sticky {
	position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    border-top: 0;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.3);
}
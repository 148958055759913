/** ========================================================================= *\
 * @Pages - Home
 * ========================================================================== */

 html {
  scroll-behavior: smooth !important;
 }

 .quill--homepage {
  .lSPrev, .lSNext {
    opacity: 1;
    @include desktop-up {
      opacity: 0;
    }
  }
  .caption{
    z-index: 99;
    font-size: 12px;
    @include tablet-up {
      font-size: 16px;
    }
  }
  footer {
    background-color: white !important;
  }
  #myBtn {
    bottom: 230px !important;
    right: 38px !important;
  }
  #image-gallery {
    .caption {
      @include desktop-lg {
        bottom: 10% !important;
      } 
    }
  }
 }

 .header-top {
  background: linear-gradient(to bottom, #005480 -50%, #002A40 120%);
}

.quill-logo-top {
  width: 100%;
  height: auto;
  max-width: 120px;
  @include tablet-up {
    max-width: 160px;
  }
}

.quill-logo {
  width: 130px;
}

.quill-logo-mobile {
  width: 75px !important;
}

.landing {
    // @include hero-section('../img/home/hometop-bg-sm.jpg', '../img/home/bg-landing.jpg');
//     background: url('../img/home/bg-landing.jpg') no-repeat; //fallback
//     // background-size: cover;
//     background-repeat:no-repeat;
//     background-size:contain;
//     background-position:center;
//     height: 50vh;
//  width: 100%;
//  background-attachment: fixed;
    h1 {
        position: relative;
        top: 15%;
    }
}

.bg-landing {
  width: 100%;
  height: auto;
}

.max-1440 {
  max-width: 1440px;
}

.post--thumbs {
    font-size: 0.9em;
}
#quillusp {
    .carousel-indicators {
        bottom: 3em;

        li {
            width: 0.6em;
            height: 0.6em;
            border-radius: 50%;
            opacity: 1;
            margin-left: 5px;
            margin-right: 5px;
        }

        .active {
            background-color: $primary-color;
        }
    }

    .carousel-item {
        padding: 6rem 0;

        // &:first-child {
            @include hero-section('../img/home/usp-public-transport.jpg', '../img/home/usp-public-transport.jpg');
        // }

        p {
            color: $secondary-color;
        }
    }

    .carousel-control-prev, .carousel-control-next {
        opacity: 1;
    }

    .carousel-control-prev-icon, .carousel-control-next-icon {
        background-color: $primary-color;
        background-image: none;
        width: 2em;
        height: 2em;
        transform: rotate(45deg);
        transition: all ease 0.3s;

        &:hover {
            transform: rotate(360deg);

            &::after {
                transform: rotate(45deg);
            }
        }

        &::after {
            content: '';
            display: block;
            position: relative;
            width: 0.8em;
            height: 0.8em;
        }
    }
    
    .carousel-control-prev-icon::after {
        top: 25%;
        left: 35%;
        border-left: 2px solid $secondary-color;
        border-bottom: 2px solid $secondary-color;
    }
    
    .carousel-control-next-icon::after {
        top: 35%;
        left: 25%;
        border-right: 2px solid $secondary-color;
        border-top: 2px solid $secondary-color;
    }
}

/*-------------------
  Introducing Quill
--------------------*/

.secondary-bg {
  background-image: url('../img/home/bg-introducing-quill.jpg');
  background-size: cover;
  background-position: center;
  min-height: 45vh;
  height: auto;
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h3 {
      color: white;
    }
  }
}

/*------------------
  Slider Fotoroma
--------------------*/

.fotorama__wrap,  .fotorama__stage__shaft {
 width: 100vw !important;
}

.fotorama1587226498784 div:nth-child(1) {
  width: 100vw !important;
}

.fotorama__thumb-border {
  border-width: 0px !important;
  background-image: none !important;
}

.fotorama__nav--thumbs .fotorama__nav__frame {
  // padding: 0!important;
  // padding-left: -3px !important;
  // padding-right: 0!important;
  padding: 17px!important;
}

.fotorama__wrap--css3 .fotorama__stage .fotorama__img {
  transition-property: opacity;
  transition-timing-function: ease;
  transition-duration: 900ms;
}

// .fotorama__nav__frame .fotorama__nav__frame--thumb {
//   padding: 20rem !important;
// }

.fotorama1587235292480 .fotorama__nav--thumbs .fotorama__nav__frame {
  padding: 20em !important;
  height: 140px;
}

.fotorama__active {
  transform: scale(1.3);
  // padding: 25px!important;
}

.fotorama__nav-wrap {
  // position: absolute;
  margin-top: -6rem;
}

/*----------------------
      Light slider
-----------------------*/

.desktop-none {
  display: block;
  @include tablet-up {
    display: none;
  }
}

.mobile-none {
  display: none;
  @include tablet-up {
    display: block;
  }
}

.card {
  border: 1px solid transparent;
}

.card-body {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: auto;
  .card-text {
    font-size: 14px;
  }
}

.card-title {
  color: black !important;
}

li a img {
  display: none;
  @include tablet-up {
    display: block;
  }
}

.lSSlideOuter .lSPager.lSpg > li a {
  background-color: transparent !important;
}

.lSPager.lSpg > li.active a {
  background-color: $secondary-color !important;
}

#content-slider {
  li {
    background-color: rgba(219, 178, 72, 0.3);
    margin-right: auto;
    cursor: pointer;
    &:hover, &:focus, &.active {
      background-color: $secondary-color !important;
    }
  }
  a {
    display: inline-block;
    overflow: hidden;
    text-indent: -999em;
    height: 8px;
    position: relative;
    z-index: 99;
    -webkit-transition: all .5s linear 0s;
    transition: all .5s linear 0s;
    &:hover, &:focus, &.active {
      background-color: $secondary-color !important;
    }
    display: block !important;
    @include desktop-up {
      display: none;
    }
  }
}

.change-color {
  background-color: $secondary-color !important;
}


/*--------------------
    Facilities Plan
----------------------*/

.facilities-plan {
  img {
    width: 100%;
    height: auto;
  }
  h3 {
    font-weight: bold;
  }
  h6 {
    color: black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 10px;
    font-family: $secondary-font-family;
  }
  p {
    color: black;
    span {
      background: $secondary-color;
      padding: 0rem 0.5rem;
      color: white;
    }
  }
}

.facilities-legend {
  margin-top: 2rem;
  @include desktop-up {
    margin-top: 0rem;
  }
}

/*---------------------
    Living room Type
----------------------*/

.siema {
  .bg-color-grey {
    background-color: #f0f0f0;
  }
  @include tablet-up {
    height: 80vh;
  }
}

.living-room-slider {
  background-size: cover;
  background-position: center;
  height: 80vh;
  display: block;
}

.slider-1 {
  background-image: url('../img/home/slider/type-s1b.jpg');
}

.slider-2 {
  background-image: url('../img/home/slider/type-bedroom-s1b.jpg');
}

.slider-3 {
  background-image: url('../img/home/slider/type-c1.jpg');
}

.slider-4 {
  background-image: url('../img/home/slider/type-bedroom-c1.jpg');
}

.slider-5 {
  background-image: url('../img/home/living-room-type-triplex.jpg');
}

.slider-6 {
  background-image: url('../img/home/bedroom-type-triplex.jpg');
}

.living-room-desc {
  padding: 3rem 0rem;
  @include tablet-up {
    padding: 0rem 0rem;
  }
}

#Path_16460 {
  &:hover, &:focus, &.active {
    stroke: $secondary-color;
    stroke-width: 1;
  stroke-linecap: butt;
  stroke-dasharray: 0;
  fill: #005480;
  }
}

.livingroom-left-btn, 
.livingroom-right-btn {
  height: 25px;
  width: 18px;
  background: transparent;
  color: #e4e4e4;
  border-color: #e4e4e4;
  border-style: solid;
  margin: 16px;
  &:hover, &:focus, &.active {
    outline: none;
  }
}

.slider-control {
  // flex-grow: 0;
  // flex-shrink: 1;
  // flex-basis: auto;
  // height: 60px;
  position: relative !important;
  padding-top: 1.5rem;
  background: #f0f0f0;
  @include tablet-up {
    padding-top: 0rem;
    background: transparent;
  }
}

.events-btn-container {
  height: 60px;
  position: absolute !important;
  bottom: 0;
  right: 15px;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: 100%;
    width: 1px;
    padding: 20px 0;

    background-color: rgb(185, 185, 185);
    background-clip: content-box;
  }
  & > button {
    appearance: none;
    border: none;
    outline: none;

    height: 100%;
    width: 65px;
    padding: 22px;

    background-color: #e4e4e4;
    // transition: all 400ms $ease-out-quart;
    cursor: pointer;

    &:before {
      content: '';
      display: block !important;
      width: 100%;
      height: 100%;

      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:hover {
      background-color: darken(#e4e4e4, 3%);
    }
  }

  .events-left-btn:before {
    background-image: url('../img/icon-left-btn-bold.svg') !important;
  }
  .events-right-btn:before {
    background-image: url('../img/icon-right-btn-bold.svg') !important;
  }
}

/*-------------------------*/

.events-img-slide {
  background-size: cover;
  background-position: center;
  // width: 100%;
  // height: calc(100vh - 250px);
  // width: 487px;
  height: 425.25px;
}

.events-details {
  height: 100%;
  flex-direction: column;

  background: #f0f0f0;

  .events-details-container {
    flex: 1;

    // @include breakpoint(max, lg) {
    //   min-height: 250px;
    // }
  }

  .events-slider-controls {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;

    height: 60px;
  }
}

.events-details-slider {
  height: 100%;

  // slide track
  & > div,
     // slide containers
     & > div > div {
    height: 100%;
  }
}

.events-details-slide {
  height: 100%;
  padding: 0px 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  .underline {
    border-bottom: 1px solid black;
    width: 100%;
    display: block;
  }
}

.events-details-title {
  p {
    font-family: 'Optima';
    color: black;
  }
}

.events-details-content {
  p {
    margin: 35px 0px;
  }
  a {
    font-family: 'Optima';
  }
}

.events-details-btn {
  border: 1px solid black;
  letter-spacing: 2px;
  padding: 10px 40px;
  display: inline-block;
  &:hover {
    color: black;
    text-decoration: none;
  }
}

.events-pagination-container {
  display: flex;
  align-items: center;
  padding-left: 5%;
}

.pagination-dot {
  height: 10px;
  width: 10px;
  background: #e4e4e4;
  margin-right: 15px;
  cursor: pointer;

  // transition: all 400ms $ease-out-quart;
  // width: 2700px;
    transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1) 0s;
    // transform: translate3d(-1620px, 0px, 0px);
}

.pagination-dot.is-active {
  background: #505050;
}

.events-btn-container {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: 100%;
    width: 1px;
    padding: 20px 0;

    background-color: rgb(185, 185, 185);
    background-clip: content-box;
  }
  & > button {
    appearance: none;
    border: none;
    outline: none;

    height: 100%;
    width: 65px;
    padding: 22px;

    background-color: #e4e4e4;
    // width: 2700px;
    transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1) 0s;
    // transform: translate3d(-1620px, 0px, 0px);
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;

      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:hover {
      background-color: darken(#e4e4e4, 3%);
    }
  }

  .events-left-btn:before {
    background-image: url('../img/icon-left-btn.svg');
  }
  .events-right-btn:before {
    background-image: url('../img/icon-right-btn.svg');
  }
}

/*-----------------
    Video
------------------*/

.container--video {
  background: url('../img/home/bg-video.jpg') no-repeat; //fallback
      background-size: cover;
      background-repeat:no-repeat;
      // background-size:contain;
      background-position:center;
      height: 70vh;
  //  width: 100%;


  // min-height: 90vh;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;
}

.transbox {
  // background: rgba(0, 0, 0, 0.3); 
  border: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.transbox h3 {
  color: white;
  opacity: 0.9;
  padding: 2.1rem 0rem;
  width: 100%;
  @include desktop-up {
    padding: 5rem 10rem;
    width: 100%;
  }
}

.header-video {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  opacity: 0;
  // overflow: hidden;
}
.header-video iframe {
  object-fit: cover;
  object-position: center;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  position: absolute;
  /* height based on 1920x1080 ratio */
  height: calc(100vw * 0.5625);
}


.muted, .stopped {
  background-color: #777;
}

/* NEW */

.video-container {
  margin-top: 55px;
}

.playbutton {
  background: url('../img/home/play-button.png') center center no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90px;
  height: 90px;
  margin: -37px 0 0 -37px;
  z-index: 10;
  opacity: 0.8;
}

.ytvideo {
  position: relative;
  // margin: 20px 0px 0px !important;
  padding: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  /* pour anciens Chrome et Safari */
  background-size: cover;
  /* version standardisée */
  cursor: pointer;

  /* margin: 0 auto; */
  display: block;
  position: relative;
  width: 100%;
  height: 70vh;
  /* @include breakpoint(min,lg) {
    max-width: 960px;
    height: 540px;
    padding: 0;
  } */
}

/* @media only screen and (max-width: 600px) {
  .ytvideo {
      max-width: 960px;
      height: 540px;
      padding: 0;
  }
} */

.ytvideo iframe {
  border-style: none;
  height: 100%;
  width: 100%;
}

.ytvideo video {
  border-style: none;
  height: 100%;
  width: 100%;
}

.ytvideo .seo {
  display: none;
}


/*-----------------
    Contact
-----------------*/

.container--contact {
  background-color: #FFFFFF;
  &:after {
    display: none;
    @include tablet-up {
      display: block;
      content: '';
      width: 2px;
      height: 80%;
      background: rgba(0, 0, 0, 0.3) ;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  a .h3 {
    text-shadow: none;
    background-color: transparent;
  }
	h3 {
    font-family: $tertiary-font-family;
    letter-spacing: 1px;
    color: black !important;
  }
  h5 {
    font-size: 16px;
    color: black !important;
  }
  p, a {
    font-family: $fourth-font-family;
  }
}

h3,h5 {
  color: black;
  font-weight: bold;
}
// fill="#f0f0f0" stroke="#DBB248"
#Ellipse_70, #Ellipse_72 {
	&:hover {
		fill: #005480;
	}
}

.container--contact-level {
  margin-top: 2em !important;
  .col-md-6:nth-child(1) {
    &:after {
      display: none;
      @include tablet-up {
        display: block;
        content: '';
        width: 2px;
        height: 95%;
        background: $secondary-color;
        opacity: 0.4;
        right: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
}


/*------------------------
      Location Map
-------------------------*/


.route-container {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 2 !important;
  width: 100% !important;
  height: 100% !important;

  // display: none;

  .dashed {
    // stroke-dasharray: 5;
  }
  #akleh,
  #jalan-sultan-ismail, 
  #monorail-1, 
  #monorail-2,
  #monorail-3 {
  //   stroke-dasharray: 1000;
  //   stroke-dashoffset: 800;
  //   fill: transparent;
  //   stroke-width: 10px;
    // animation: draw 5s linear forwards !important;
    animation:  blink 2s linear infinite;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.active {
    display: block !important;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes blink {
  // 0% {opacity: 0}
	// 49%{opacity: 0}
  // 50% {opacity: 1}
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}



//close absolute route

.selector-menu li span {
  width: 330px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  max-width: initial;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-bottom: 1px solid $secondary-color;
  margin: 0px 5px;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  transition: all 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  display: none;
  height: 12px;
  &.active, &:focus, &:hover {
    transform: translateX(0%) !important;
  }
}

.selector-menu li {
  // padding: 7px 0px;
    display: flex;
    white-space: nowrap;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    overflow: hidden;
    font-family: $secondary-font-family;
  &.active {
    span {
      transform: translateX(0%) !important;
      @include desktop-up {
        display: block !important;
      }
    }
  }
}


#masterplan {
  background: linear-gradient(to bottom, #005480 -50%, #002A40 120%);
  .line {
    border-top: 1px solid grey;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 20px;
  }
  h5 {
    font-family: $tertiary-font-family;
    text-transform: uppercase;
    font-weight: 700;
    color: white;
    letter-spacing: 1px;
    font-size: 16px;
    @include desktop-up {
      display: table-caption;
      padding-left: 2rem;
    }
  }
  h3, li, p {
    color: white !important;
  }
  .selector-menu li {
    text-transform: uppercase !important;
  }
  p {
    font-weight: 400 !important;
  }
  .legend-border {
    border: 1px solid $secondary-color;
    margin-bottom: 3rem;
    @include desktop-up {
      margin-bottom: 0rem;
    }
    h6 {
      font-family: $fourth-font-family;
    }
  }
  .location-icon-legend {
    max-width: 3rem;
  }
  &:before {
    // @extend %woodgrain-pattern-grey;
    top: 12%;
    right: 0;
    width: 9em;
    height: 4.5em;
    background-size: 23em auto;
    background-position: left -70px top -70px;
  }
  #facilities {
    .facilities-pinpoints {
      position: relative;
      // margin-bottom: 3em;
      font-size: 1rem;
      img {
        max-width: 99.5%;
      }
      .bg-0km {
        background: url('../img/location-map/0km.svg') center no-repeat;
      }
      .bg-0-1km {
        background: url('../img/location-map/0.1km.svg') center no-repeat;
      }
      .bg-0-4km {
        background: url('../img/location-map/0.4km.svg') center no-repeat;
      }
      .bg-0-8km {
        background: url('../img/location-map/0.8km.svg') center no-repeat;
      }
      .bg-1km {
        background: url('../img/location-map/1km.svg') center no-repeat;
      }
      .bg-1-4km {
        background: url('../img/location-map/1.4km.svg') center no-repeat;
      }
      .bg-1-7km {
        background: url('../img/location-map/1.7km.svg') center no-repeat;
      }

      .bg-2km {
        background: url('../img/location-map/2km.svg') center no-repeat;
      }
      .bg-2-1km {
        background: url('../img/location-map/2.1km.svg') center no-repeat;
      }
      .bg-2-2km {
        background: url('../img/location-map/2.2km.svg') center no-repeat;
      }
      .bg-2-3km {
        background: url('../img/location-map/2.3km.svg') center no-repeat;
      }
      .bg-2-5km {
        background: url('../img/location-map/2.5km.svg') center no-repeat;
      }
      .bg-2-7km {
        background: url('../img/location-map/2.7km.svg') center no-repeat;
      }

      .bg-3km {
        background: url('../img/location-map/3km.svg') center no-repeat;
      }
      .bg-3-4km {
        background: url('../img/location-map/3.4km.svg') center no-repeat;
      }
      .bg-3-6km {
        background: url('../img/location-map/3.6km.svg') center no-repeat;
      }
      .bg-4-5km {
        background: url('../img/location-map/4.5km.svg') center no-repeat;
      }
      .pinpoint {
        // background: url('../img/location-map/0.4km.svg') center no-repeat;
        background-size: auto 100%;
        width: 1em;
        height: 1.3em;
        position: absolute;
        @include tablet-up { 
          width: 1.5em;
          height: 1.8em;
        }

        
        @include desktop-up { 
          width: 2.5em;
          height: 3em;
        }
        &.pin-1 {
          left: 32.5%;
          bottom: 46%;
        }
        &.pin-2a {
          left: 27%;
          top: 28%;
        }
        &.pin-2b {
          left: 42%;
          top: 29.5%;
        }
        &.pin-2c {
          right: 48%;
          bottom: 54%;
        }
        &.pin-2d {
          left: 44.8%;
          top: 45.5%;
        }
        &.pin-2e {
          left: 50.7%;
          top: 23.3%;
        }
        &.pin-2f {
          left: 63.1%;
          top: 48.6%;
        }
        &.pin-3a {
          left: 54%;
          top: 51.8%;
        }
        &.pin-3b {
          left: 19.3%;
          top: 24%;
        }
        &.pin-3c {
          left: 64.5%;
          top: 18%;
        }
        &.pin-3d {
          left: 40.6%;
          top: 43.3%;
        }
        &.pin-4a {
          left: 33.5%;
          top: 21.3%;
        }
        &.pin-4b {
          left: 28%;
          top: 62.8%;
        }
        &.pin-4c {
          left: 91.5%;
          top: 19.8%;
        }
        &.pin-4d {
          left: 33%;
          top: 6.8%;
        }
        &.pin-4e {
          left: 34.5%;
          top: 47.8%;
        }
        &.pin-4f {
          left: 15.5%;
          top: 19%;
        }
        &.pin-5a {
          left: 85.6%;
          top: 21%;
        }
        &.pin-5b {
          right: 56.8%;
          top: 81.2%;
        }
        &.pin-5c {
          right: 15.7%;
          top: 65.2%;
        }

        &.pin-6a {
          left: 29.2%;
          bottom: 73.5%;
        }
        &.pin-6b {
          left: 70.5%;
          top: 16.3%;
        }
        &.pin-6c {
          left: 64.2%;
          bottom: 73.5%;
        }
        &.pin-6d {
          left: 59.8%;
          top: 72.5%;
        }
        &.pin-6e {
          left: 2.6%;
          top: 0%;
        }

        &.pin-7 {
          left: 46.5%;
          top: 35.5%;
        }
        &.pin-8 {
          right: 25.2%;
          top: 48.2%;
        }
        &.pin-9a {
          left: 8.5%;
          top: 14.5%;
        } 
        &.pin-9b {
          right: 6%;
          top: 29.5%;
        } 
        &.pin-10 {
          left: 37.2%;
          top: 28%;
        }
      }
    }
    
    #facilities-selector {
      text-align: left;
      padding: 0 1em;
      @include tablet-up {
        padding: 0 2em;
      }
      p {
        @include desktop-up {
          padding-right: 2rem;
        }
      }
      h4 {
        text-align: left;
        font-weight: 900;
        margin-bottom: -0.1em;
      }
      span.sub {
        font-size: 0.7em;
        margin-bottom: 0.8em;
        display: inline-block;
        color: $secondary-color;
      }
      ol.menu {
        list-style-type: none;
        font-size: 0.8em;
        padding: 1em 0 0 1em;
        margin: 0.2em 0 2.5em;
        text-align: left;
        &.selector-menu {
          li {
            font-size: 15px !important; 
            letter-spacing: 1px;
            padding: 0.5rem 0rem;
            cursor: pointer;
              .desc {
                font-weight: normal;
                padding: 0.3em 0.6em;
                color: white;
                background-color: $secondary-color;
                margin-bottom: 0.7em;
                display: none;
                cursor: pointer;
              }
            &:hover {
              // color: $secondary-color !important;
              font-weight: bold !important;
            }
            &.active {
              font-weight: bold;
              text-decoration: none;
              color: $secondary-color;
              .desc {
                display: block;
              }
            }
          }
        }

      }
    }
  }
}

.animate-floating {
  animation: floating 0.5s infinite alternate;
}

@keyframes floating {
    0% { transform: translateY(0); }
    100% { transform: translateY(-3px); }
}

.hide {
	display: none;
}

.five-cols {
  padding-left: 1rem;
  @include desktop-up {
    padding-left: 0rem;
  }
}

.five-cols .col-lg-3 {
  display: inline-block;
  @include desktop-up {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    font-size: 12px;
  }
  .location-icon-legend {
    float: left;
    padding-right: 0.5rem;
    padding-top: 0.2rem;
  }
  h6 {
    letter-spacing: 0.03em;
    font-family: $body-font;
    font-size: 12px;
    color: white !important;
    padding-left: 0.3rem;
    padding: 0px 0px 0px 30px;
    @include desktop-up {
      width: 9rem;
    }
  }
  .hide-title {
    display: none !important;
    @include desktop-up {
      display: block;
    }
  }
}


// absolute route

.map-container {
  height: 100%;
  img {
    width: 100%;
    height: auto !important;
  }
}


/*-----------------------
    Thank You Page
-----------------------*/

.container-thank-you {
  background: linear-gradient(to bottom, #005480 0%, #002A40 130%);
  height: 100vh;
  .headline {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -35%);
    @include tablet-up {
      top: 45%;
    }
    p {
      width: 100%;
      margin: auto;
      color: white;
      @include tablet-up {
        width: 80%;
      }
    }
  }
  // .btn-back-to-home {
  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   transform: translate(-50%, -50%);
  // }
}

@media (max-width: 480px) { 
  .container-thank-you .headline h2 {
    font-size: 35px;
  }
}


/*  ===============================================
    Load Font
    ===============================================
*/

//Playfair
@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/PlayfairDisplay-Bold.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

//Century Gothic
@font-face {
    font-family: 'Century Gothic';
    src: url('../fonts/CenturyGothic.woff2') format('woff2'),
        url('../fonts/CenturyGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro Light';
    src: url('../fonts/SofiaPro-Light.woff2') format('woff2'),
        url('../fonts/SofiaPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro Bold';
    src: url('../fonts/SofiaPro-Bold.woff2') format('woff2'),
        url('../fonts/SofiaPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

// Chinese fonts
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+SC|Noto+Serif+SC&display=swap');
/*-----------------------------
      Register Popup
-------------------------------*/

section.socials {
  position: fixed;
  z-index: 99;
  right: 0.8rem;
  bottom: 3%;
  display: grid;
  padding: 0rem 1rem;
}

.register-popup {
  -webkit-animation-duration: 0.7s;
	animation-duration: 0.7s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
	animation-fill-mode: forwards;
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	-moz-animation-name: fadeInUp;
	-o-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

 
.social-icon {
  margin-bottom: 0.5rem;
  svg #circle {
    &:hover, &:focus, &.active {
      fill: #95784a !important;
      opacity: 1 !important;
    }
  }
}

.open-button {
  padding: 16px 18px;
  width: 60px;
}

/* Button used to open the chat form - fixed at the bottom of the page */
.open-button,
.social-icon {
  background-color: #DBB248 !important;
  border: none;
  cursor: pointer;
  border-radius: 42px !important;
  outline: none;
  text-decoration: none;
  &:hover, &:focus, &.active {
    background-color: #95784a !important;
    opacity: 0.8;
    outline: none;
    text-decoration: none;
  }
  img {
    width: 1.2rem;
  }
}

/* The popup chat - hidden by default */
.register-popup {
  display: none;
  position: fixed;
  bottom: 15px;
  right: 15px;
  border:  none;
  z-index: 999;
  form {
    width: 100%;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4;

  }
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}

@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}

/* Add styles to the form container */
.form-container {
  max-width: 100%;
  padding: 1.5rem;
  background-color: #EDEAE6;
  @include tablet-up {
    max-width: 520px;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
  .form-check {
    margin-top: 0.5rem;
  }
  .form-text {
    margin-bottom: 0.5rem;
  }
  .form-control {
    font-size: 0.8rem;
  }
  .form-text,
  .form-check-label,
  .form-group label {
    font-size: 12px;
    color: black !important;
  }
  .btn-primary {
    background-color: #7A7261;
    &:hover, &:focus {
      border: 1px solid #7A7261 !important;
      color: #7A7261 !important;
    }
  }
  .styled-checkbox + label {
    cursor: auto;
  }
  .styled-checkbox + label:before {
    margin-top: 2.1px;
  }
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  // background-color: #4CAF50;
  color: white;
  padding: 0.375rem 2rem;
  border: none;
  cursor: pointer;
  // width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.close-register-popup {
  // background-color: red;
  position: absolute;
  top: 10px !important;
  right: 10px;
  color: #7A7261;
  z-index: 10;
  border: 2px solid #7A7261 !important;
  border-radius: 40px;
  text-decoration: none !important;
  outline: none !important;
  padding: 0px 8px 2px !important;
  span {
    font-size: 1.8rem;
    padding-top: -1px;
    line-height: 1.0;
  }
  &:hover, &:focus, &.active {
    color: white;
    background-color: #7A7261;
  }
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}

@media only screen and (min-width: 480px) 
                   and (max-width: 823px) 
                   and (orientation: landscape) {
    .form-container {
      max-width: 400px;
      // margin: 10rem 0rem 0rem;
  }
  .register-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 15px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
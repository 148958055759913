/** ========================================================================= *\
 * @Layout: Footer
 * ========================================================================== */

footer {
    background: #F0F0F0;

    h5 {
        margin-bottom: 1em;
    }

    p {
        color: black;
        font-size: 0.8em;
        font-family: $fourth-font-family;
    }
    h3 {
        font-family: $tertiary-font-family;
    }

    a {
        color: black;
        font-weight: normal;
        font-size: 13px;
        font-family: $fourth-font-family;
        &:hover, &:focus, &.active {
            color: $primary-color;
            text-decoration: none;
        }
    }

    .about-col {
        margin-top: -2.5em;
    }

    input {
        font-family: $secondary-font-family;
        border-color: $primary-color;
        border-style: solid;
        padding: 0.2em 0.5em;

        &::placeholder {
            color: $footer-grey-color;
        }
    }

    button {
        background-color: $primary-color;
        border-color: $primary-color;
        border-style: solid;
        color: $secondary-color;

        &::after {
            content: '\00FE65';
            display: inline;
            font-weight: 2em;
        }
    }

    .checkbox-box {
        opacity: 0;
    }

    .copyright {
        text-align: center;
        @include desktop-up {
            text-align: right;
        }
    }
   .row div:nth-child(1) {
       text-align: center;
       @include desktop-up {
           text-align: left;
       }
   }
}

.footercontent-row {
    padding-top: 5em;
    padding-bottom: 2em;

    > div {
        padding-bottom: 2em;
    }
}

.backtotop {
    width: 2em;
    height: 2em;
    background: $primary-color;
    border-right: 2px solid $dark-primary-color;
    border-bottom: 2px solid $dark-primary-color;
    display: block;
    position: relative;
    top: -1em;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);

    &::after {
        content: '';
        width: 0.6em;
        height: 0.6em;
        border-top: 2px solid $secondary-color;
        border-right: 2px solid $secondary-color;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
    }
}
/*--------------- POPUP ------------------ */

#myModal {
	.close {
		position: absolute;
		top: 30px !important;
		right: 30px;
		color: white;
		z-index: 10;
		border: 2px solid white !important;
		border-radius: 40px;
		text-decoration: none !important;
		outline: none !important;
		padding: 0px 6px 5px !important;
		&:hover, &.active, &:focus {
			color: $secondary-color;
			border: 2px solid $secondary-color !important;
		}
	}
	
	.close-symbol {
		text-shadow: none;
		top: 0;
	}
}

.modal-outer {
	max-width: 700px;
	background-size: cover;
	background-attachment: scroll;
	background-repeat: no-repeat;
	margin: 0;
	width: 98%;
	background-position: 50%;	
	@include tablet-up {
		width: 100%;
	}
}

.frame {
	max-height: 100%;
	background-color: white;
}

.modal-inner {
	background-size: 100% auto;
	background-repeat: no-repeat;
	max-width: 780px;
	background-color: white;
	background-position: bottom center;
	padding: 15px 15px 0px 15px;
	width: 100%;
	height: auto;
}

.modal-inner-2 {
	padding-top :0;
}

.my-modal {
	text-align: center;
	-webkit-animation-duration: 0.7s;
	animation-duration: 0.7s;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	animation-fill-mode: forwards;
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}
	
	100% {
		
		opacity: 1;
		transform: translateY(0);
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-o-animation-name: fadeInDown;
	animation-name: fadeInDown;
}

.my-modal:before {
    content: " ";
    height: 100%;
  }

.modal-dialog-2 {
	text-align: center;
	vertical-align: middle;
	position: absolute;
  top: 50%;
  left: 50%;
	transform: translate(-50%, -50%);
	@include tablet-up {
		transform: translate(-50%, -50%);
	}
}

.modal-text-1 {
	color: black;
	font-size: 22px;
	letter-spacing: 0.1px;
	font-weight: 600;
	font-family: 'Muli', sans-serif;
	margin-top: 0;
	margin-bottom: 5px;
	margin-top: 15px;
	line-height: 27px;
}

.modal-text-2 {
	color: black;
	font-size: 22px;
	letter-spacing: 0.1px;
	font-family: 'Muli', sans-serif;
	margin-top: 0;
	margin-bottom: 5px;
	margin-top: 10px;
	line-height: 27px;
}


/*-----------------
      Carousel 
------------------*/

#myModal {
  .carousel-indicators {
		top: 56% !important;
		opacity: 0;
		@include tablet-up {
			opacity: 1;
			top: 70% !important;
		}
	}
	.carousel-indicators {
		height: 16px;
	}
  .carousel-indicators li {
    background-color: #DCDCDC;
    border: 1px solid #DCDCDC;
    border-radius: 100%;
    height: 8px;
    width: 8px;
    letter-spacing: 2px;
    &:hover, &.active, &:focus {
      background-color: #696969;
      border: 1px solid #696969;
    }
	}
	.carousel-item img {
		max-height: 420px;
		height: 100%;
		width: 100%;
	}
  .carousel-caption {
    position: relative;
    left: auto;
		right: auto;
		margin-top: 2rem;
		@include tablet-up {
			margin-top: 2.5rem;
		}
		h5 {
			font-family: $secondary-font-family;
			font-size: 16px;
		}
		h3 {
			font-family: $secondary-font-family;
			font-size: 18px;
			text-transform: uppercase;
			font-weight: 700;
			color: black;
			padding-bottom: 20px;
			width: 93%;
			margin: auto;
			height: auto;
			@include tablet-up {
				width: 90%;
				margin: auto;
				height: 65px !important;
			}
		}
	}
	.carousel-control-prev,
	.carousel-control-next {
		bottom: 51%;
		@include tablet-up {
			display: none;
		}
	}
}

@media only screen and (max-width: 340px) {
  #myModal {
		.carousel-indicators {
			top: 50% !important;
		}
	}
}


@media only screen and (min-width: 480px) 
                   and (max-width: 823px) 
                   and (orientation: landscape) {
	.modal-outer {
		width: 50%;
		margin: 5rem 0rem 1rem;
	}
	#myModal {
		.carousel-indicators {
			opacity: 0;
		}
	}
}

/*-----------CLOSE POPUP---------------*/
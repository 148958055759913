/*------------------------
    Living @ Quill
--------------------------*/

.quill--living-at-quill {
  background: linear-gradient(to bottom, #005480 -50%, #002A40 120%);
  .header-top {
    background: linear-gradient(to bottom, #005480 -5%, #002A40 90%) !important;
    p {
      color: white;
      width: 80%;
      margin: auto;
      @include desktop-up {
        width: 55%;
      }
    }
  }
  
  .caption {
    z-index: 99;
    color: white;
    position: absolute;
    width: 80%;
    left: 50%;
    transform: translateX(-50%) !important;
    @include desktop-lg {
      bottom: 10% !important;
      width: 50%;
    } 
    p {
      color: white;
    }
  }
  h3 {
    color: $secondary-color;
    letter-spacing: 1px;
  }
  .description {
    background-color: white;
    p {
      margin: auto;
      @include tablet-up {
        width: 60%;
      }
    }
  }
  footer {
    background: transparent;
    p, a {
      color: white;
    }
  }
  #bg-half {
    display: block;
    height: 100%;
    // background-image: linear-gradient(to bottom, white 52%, #002A40 48%);
    background-position: bottom, top;
    background-repeat: no-repeat;
    width: 100%;
    z-index: 00;
  }
}

// Slider

// Overlay an image 

// #overlay {
//   position: absolute;
//   top: 0;
//   left: 0;
//   clear: float;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(255, 255, 255, 0.45);
//   color: #ffffff;
// }

.slick-slide img {
  width: 100%;
  padding: 1rem;
  opacity: 0.6;
}

.slick-current img {
  opacity: 1;
}

.slick-current .container-slide-item {
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  transform: scale(1.25);
  opacity:1;

  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;

    // padding: 4rem 1rem 1rem;
    @include desktop-up {
      // padding: 4rem 1rem 1rem;
    }
}

.slick-slide p,
.slick-slide h5 {
  display: none;
}

.slick-current p,
.slick-current h5 {
  display: block !important;
  color: white;
  padding: 1rem 0rem;
}

.slick-current p {
  font-weight: 400;
  font-size: 12px;
  margin-top: -30px;
}

.container-slide-item {
  div{
    @include desktop-up {
      min-height: 200px;
    }
  }
}

.container-slide-item {
  background:transparent;
  font-size:36px;
  margin:10px;
  padding:2%;
  position:relative;
  text-align:center;
  .top {
    height: 70%;
    color: black;
    margin: 0 auto;
  }
}

.slick-slide {
  cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  &.active, &:focus, &.hover {
    outline: none;
    text-decoration: none;
  }
}

.center .slick-center .container-slide-item {
  //-moz-transform:scale(1.08);
  //-ms-transform:scale(1.08);
  //-o-transform:scale(1.08);
  //-webkit-transform:scale(1.08);
  //transform:scale(1.08);
  //width: 150px;
  //height: 120px;
  // color:#e67e22;
  opacity:1;
  .top {
    // width: 90%;
  }
}


@media (max-width: 480px) { 
  .slick-desc {
    margin-bottom: 4rem;
    width: 88% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
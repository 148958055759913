/*------------------
  Overview site map
--------------------*/

.container-overview-map {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.map-container {
  position: relative;
  display: inline-block;
}

/*----------------
    Hover point
-----------------*/

.point {
  position: absolute;
  cursor: pointer;
  align-items: center;
  z-index: 5;
}

.point:hover,
.point.active {
  z-index: 10;
}

.point-education {
  display: flex;
}

.point-education {
  color: white !important;
  .point-rect {
    background-color: #DBB248;
    border: 1px solid transparent;
    padding: 0px 2px;
    // border-radius: 12px;
  }
  .point-legend {
    background-color: #113c64;
    color: #DBB248;
    border: 1px solid #DBB248;
  }
  .right.arrow:after {
    border-right: 10px solid #DBB248;
  }
  .left.arrow:after {
    border-left: 10px solid #DBB248;
  }
}

.point-rect {
  font-family: 'Seravek', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.4rem;
  
  @include tablet-up {
    font-size: 0.55rem;
  }
  @include desktop-up {
    font-size: 0.7rem;
    width: 1.2rem;
    height: 1.2rem;
  }
}

// .point-rect:hover + .point-legend {
//   width: auto;
//   opacity: 1;
//   visibility: visible;
//   z-index: 2;
// }

// .point-rect:hover + .point-legend.right {
//   left: 100%;
// }

// .point-rect:hover + .point-legend.left {
//   right: 100%;
// }

.point:hover .point-legend,
.point.active .point-legend {
  width: auto;
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

.point:hover .point-legend.right,
.point.active .point-legend.right {
  left: 100%;
}

.point:hover .point-legend.left,
.point.active .point-legend.left {
  right: 100%;
}



.point-legend {
  position: absolute;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 1.2rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-family: $secondary-font-family;
  padding: 12px;
  pointer-events:none;
  font-size: 8px;
  margin: 0px 2px;
  @include tablet-up {
    margin: 0px 8px;
    font-size: 12px;
  }
}

@include desktop-up {
  .arrow:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    position: absolute;
    content: '';
    top: 50%;
    // right: 100%;
    transform: translateY(-50%);
  }
  .right.arrow:after {
    right: 100%;
  }
  .left.arrow:after {
    left: 100%;
  }
  .arrow:before {
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 15px solid #6F1B37;
    top: 19px;
    left: -15px;
  }
}

.map-line {
  width: 2vw;
  text-align: left;
  position: absolute;
  top: 42%; 
  left: 50.6%;
  z-index: 1;

  // @include desktop-up-lg {
  //   width: 4vw;
  //   top: 43%; 
  //   left: 50.6%;
  // }

  // @include desktop-up-xlg {
  //   top: 45%; 
  // }
}

.map-logo {
  width: 13vw;
  text-align: left;
  position: absolute;
  top: 33.5%; 
  left: 51.6%;
  z-index: 2;

  // @include desktop-up-lg {
  //   top: 35.5%; 
  //   left: 51.4%;
  // }

  // @include desktop-up-xlg {
  //   top: 37.5%; 
  // }
}

section.form {
  background: linear-gradient(to bottom, #005480 0%, #002A40 130%);
  p, a, u {
    color: white;
    text-decoration: none;
    outline:none;
  }
  a:hover, a.active,
  u:hover, u.active {
    color: $secondary-color;
  }
}
form {
  width: 80%;
  padding-bottom: 4rem;
  // text-align: left;

  @include tablet-up {
    width: 50%;
  }
}

.form-group {
  text-align: left;
}

.form-group label,
.form-text {
  color: white !important;
  text-align: left;
  font-family: $secondary-font-family;
  font-size: 15px;
}

.form-group label {
  margin-bottom: 0.1rem;
}

.form-text {
  margin-bottom: 1.2rem;
}

.form-control {
  border-radius: 2rem;
}

input::placeholder {
  color: lighten($light-grey-color, 10%) !important;
}

.btn-primary {
  letter-spacing: 1px;
  color: $white-color;
  background-color: $secondary-color;
  border-color: $secondary-color;
  border-radius: 2rem;
  padding: 0.375rem 2rem;
  transition: all 0.15s ease-in-out;
  transform: translateY(2px);
  font-family: $tertiary-font-family;
  font-size: 0.9rem;
}

.btn-primary:hover, .btn-rimary.active, .btn-primary:focus {
  background-color: transparent !important;
  border-color: $secondary-color!important;
  // transform: translateY(0);
  // box-shadow: 0px 2px 5px rgba($secondary-color, 0.5);
  color: $secondary-color!important;
  font-weight: bold!important;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  background-color: transparent !important;
  border-color: $secondary-color!important;
  color: $secondary-color!important;
  font-weight: bold!important;
}

.form-check {
margin-top: 1.5rem;
}

.form-check-label {
  font-size: 15px;
  font-family: $secondary-font-family;
}

.styled-checkbox {
position: absolute; // take it out of document flow
opacity: 0; // hide it

& + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

// Box.
& + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: -0.1rem;
    width: 15px;
    height: 15px;
    background: $white-color;
    border-radius: 5rem;
    transition-duration: 0.3s;
}

// Box hover
&:hover + label:before {
  background: $secondary-color;
}

// Box focus
&:focus + label:before {
  box-shadow: 0 0 0 3px rgba(211, 211, 211, 0.5);
}

// Box checked
&:checked + label:before {
  background: $secondary-color;
}

// Disabled state label.
&:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}

// Disabled box.
&:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

// Checkmark. Could be replaced with an image
&:checked + label:after {
  content: '';
  position: absolute;
  left: 2px;
  top: 9px;
  background: $white-color;
  width: 2px;
  height: 2px;
  box-shadow: 
    2px 0 0 $white-color,
    4px 0 0 $white-color,
    4px -2px 0 $white-color,
    4px -4px 0 $white-color,
    4px -6px 0 $white-color,
    4px -8px 0 $white-color;
  transform: rotate(45deg);
}
}

.form-check-input {
margin-left: 0;
z-index: 1;
}
/** ========================================================================= *\
 * @Variables
 * ========================================================================== */

// Font weights
$light: 300;
$regular: 500;
$bold: 700;
$ultra-bold: 800;
$uppercase: uppercase;

// Fonts
$primary-font-family: 'Playfair Display';
$secondary-font-family: 'Century Gothic';
$tertiary-font-family: 'Sofia Pro Bold';
$fourth-font-family: 'Sofia Pro Light';

$heading-font: $primary-font-family;
$body-font: $secondary-font-family;

$chinese-primary-font-family: 'Noto Serif SC', serif;
$chinese-secondary-font-family: 'Noto Sans SC', sans-serif;

$chinese-headline-font: $chinese-primary-font-family;
$chinese-body-font: $chinese-secondary-font-family;

// Fonts Color
$primary-color: #DBB248;
$secondary-color: #EFEDE6;
$tertiary-color: #003B5C;
$dark-color: #707070;
$grey-color: #9F9A9A;

$dark-primary-color: #897031;

$footer-grey-color: #DEDCD6;
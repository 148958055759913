.quill--residences {
  background-color: #FFFFFF;
  .hero {
    background-image: url('../img/quill-residences/quill-residences-hero.jpg');
    background-size: cover;
    background-position: center;
    min-height: 50vh;
    height: auto;
  }
}

// .hero {
//   background-image: url('../img/home/bg-introducing-quill.jpg');
//   background-size: cover;
//   background-position: center;
//   min-height: 45vh;
//   height: auto;
// }

.bg-white {
  background-color: white;
}

.nav-tabs {
 border-bottom: none;
}

.nav-tabs li {
  // max-width: 200px;
  border: 1px solid #7A7261 !important; 
  width: 180px;
  padding: 1rem 3rem !important;
  border-radius: 1rem !important;
  background-color: transparent;
  margin: 0.5rem auto;
  @include desktop-up {
    text-align: center;
    padding: 0.6em 0rem !important;
    width: 13.6666% !important;
    margin: 1rem 1rem 0rem 0rem;
  }
  &:hover, &:focus, &.active {
    background-color: #7A7261; 
    text-decoration: none;
    outline: none;
    a {
      color: white;
    }
  }
  a {
    display: block;
    font-family: $heading-font;
    letter-spacing: 1px;
    // padding: 3rem 3rem !important;
    font-weight: 700;
    color: #7A7261; 
    &:hover, &:focus, &.active {
      text-decoration: none;
      outline: none;
    }
  }
}

.tab-pane.active {
  // animation: slide-down 0.7s ease-out;
}

@keyframes slide-down {
 0%{opacity:0;-webkit-transform:scale3d(.3,.3,.3);transform:scale3d(.3,.3,.3)}50%{opacity:1}
}


/*-----------------------
    Specifications
------------------------*/

.quill--residences {
  section {
    background-color: #F0F0F0;
    padding-top: 3rem;
  }
  img {
    width: 100%;
    height: auto;
  }
  .title {
    display: flex;
    align-items: center;
    margin-top: 3rem;
  }
  
  .line {
    border-top: 1px solid grey;
    flex-grow: 1;
    margin: 0 20px;
  }
}

table tr td:nth-child(1) {
  background-color: #EDEAE6;
  border-bottom: 1px solid rgba(147, 149, 152, 0.2);
  font-weight: bold;
  width: 125px;
}

table tr td:nth-child(2) {
  background-color: white;
  border-bottom: 1px solid rgba(147, 149, 152, 0.2);
}

td {
  font-family: $body-font;
  font-size: 13px;
  padding: 0.3rem  1rem;
}

/*-----------------------------
      Skyline View
----------------------------*/

.container-skyline {
  background-color: white;
  padding: 2rem 2rem;
  @include tablet-up {
    padding: 2rem 5rem 3rem;
  }
  h4 {
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    // font-family: $body-font;
  }
}


/*---------------------
    Unit Plans
----------------------*/

.quill--residences {
  .nav-tabs span {
    display: none;
    font-family: $body-font;
    font-size: 13px;
    font-weight: 400;
    @include tablet-up {
      display: block;
    }
  }
}

.unit-plans-type {
  color: #7A7261 !important;
}

.unit-plans-keyplan {
  font-weight: 700 !important;
  color: #7A7261 !important;
  margin-top: 2.2rem;
  margin-bottom: 0.1rem;
  @include tablet-up {
    margin-top: 0rem;
  }
}

.container--keyplan {
  margin-top: 0rem;
  text-align: center;
  @include tablet-up {
    margin-top: -7rem;
    text-align: left;
  }
  img {
    max-width: 150px;
    @include tablet-up {
      max-width: 250px;
    }
  }
}

/*---------------------
  Furnishing Details
----------------------*/

.img-furnishing-details {
  width: 90% !important;
}

.display-mobile {
  display: block;
  @include desktop-up {
    display: none;
  }
  a {
    font-weight: 400 !important;
  }
}

/*---------------------
      E-Brochure
----------------------*/

.img-e-brochure {
  max-width: 320px;
}

.title-e-brochure {
  @include tablet-up {
    height: 80px;
  }
  @include desktop-up {
    height: 80px;
  }
  @include desktop-lg {
    height: 50px;
  }
}

.btn-e-brochure {
  display: inline-block;
  color: black;
  border: 1px solid #7A7261;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  background-color: white;
  font-family: $secondary-font-family;
  margin: 0.5rem 0rem;
  &:hover {
    text-decoration: none !important;
    background-color: #7A7261;
    color: white;
    transition: all 0.15s ease-in-out;
  }
  img {
    float: left;
    max-width: 25px;
    padding-right: 0.5rem;
  }
}

.fa-file-pdf {
  // font-size:48px;
  color: red;
}

/*-------------------
    Site Progress
---------------------*/

main.container-site-progress {
  position: relative;
  @include on-mobile-only {
    min-height: 100vh;
    height: auto;

  }
  .gallery {
    @include on-mobile-only {
      position: relative;
      min-height: 58vh;
      height: auto;
      display: grid;
      align-items: center;
      justify-content: center;
    }
  }
  .gallery .lSSlideWrapper .lSFade > *  {
    @include on-mobile-only {
      top: auto !important;
    }
  }
  .gallery .lSSlideWrapper {
    @include on-mobile-only {
      position: initial;
    }
  }
  footer {
    @include on-mobile-only {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

.caption-date {
  position: absolute;
  top: 80%;
  width: 50%;
  left: 50%;
  padding: 0 1rem;
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
    p {
      display: inline-block;
      padding: 10px 0;
      background-color: rgba(0, 0, 0, 0.4) !important;
    }
}

.caption-date .caption {
  top: 65%;
  width: 65%;
  @include tablet-up {
    top: 80%;
    width: 30%;
  }
}
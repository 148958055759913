/** ========================================================================= *\
 * @Mouse Scroll Icon
 * ========================================================================== */

.scroll-icon {
    position: absolute;
    display: flex;
    color: $secondary-color;
    font-size: smaller;
    font-weight: 500;
    left: 46%;
    transform: translateX(-50%);
    bottom: 5%;
}

.mouse {
    position: relative;
    width: 20px;
    height: 35px;
    border: 1px solid $secondary-color;
    border-radius: 15px;
    margin-right: 10px;
    
    &::before {
        content: '';
        width: 1px;
        height: 6px;
        position: absolute;
        background: $secondary-color;
        left: 50%;
        transform: translateX(-50%);
        margin: 5px 0;
        animation: mousescroll 1.7s ease-in-out infinite;
    }

    &::after {
        content: 'Scroll to discover';
        display: flex;
        width: 110px;
        margin-left: 25px;
        margin-top: 7px;
    }
}

@keyframes mousescroll {
from {
    opacity: 1;
}

to {
    opacity: 0;
    transform: translateY(20px);
}
}


/*------------------------------
      Scroll Top Button
-------------------------------*/


#myBtn {
    display: none;
    position: fixed;
    bottom: 230px;
    right: 38px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    padding: 5px 15px;
    background-color: $secondary-color;
    opacity: 0.8;
  }
  
  #myBtn:hover {
    background-color: #95784a;
    opacity: 0.8;
  }
/* Switch container */
.switch-container {
  z-index: 10 !important;
  position: absolute;
  top: 5%;
  left: 2%;
  display: block;
  @include desktop-up {
    z-index: 1000 !important;
    display: none;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 5.5rem;
  height: 2rem;
}

/* The slider switch */
.slider-lang {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 2;
  background-color: #003B5C;
  border-radius: 20px;
  border:1px solid white;
  transition: 0.4s;

  /* The switch button */
  &:before {
      position: absolute;
      content: "EN";
      color: $secondary-color;
      width: 3rem;
      height: 2rem;
      left: -0.1rem;
      bottom: 0rem;
      padding-top: 0.1rem;
      background-color: white;
      border-radius: 20px;
      font-weight: 700;
      transition: 0.4s;
      z-index: 1;
  }

  &:after {
      position: absolute;
      content: "中文";
      color: white;
      width: 3rem;
      height: 2rem;
      right: 0rem;
      bottom: 0rem !important;
      padding-top: 0.1rem;
      @include tablet-up {
        bottom: -0.1rem !important;
      }
  }
}

.cnLang {
  & .slider-lang:before {
      transform: translateX(2.5rem);
      content: "中文" !important;
      color: $secondary-color;
      left: -0.01rem;
  }

  & .slider-lang:after {
      transform: translateX(-2.5rem);
      content: "EN" !important;
      color: white;
  }
}


/*-----------------------
  Language on Desktop 
------------------------*/

/* Switch container */
.switch-container-desktop {
  z-index: 10 !important;
  @include desktop-up {
    z-index: 1000 !important;
  }

  
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 5.5rem;
  height: 2rem;
}

/* The slider switch */
.slider-lang {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 2;
  background-color: white;
  border-radius: 20px;
  border:1px solid $secondary-color;
  transition: 0.4s;

  /* The switch button */
  &:before {
      position: absolute;
      content: "EN";
      color: white;
      width: 3rem;
      height: 2rem;
      left: -0.1rem;
      bottom: 0rem;
      padding-top: 0.1rem;
      background-color: $secondary-color;
      border-radius: 20px;
      font-weight: 700;
      transition: 0.4s;
      z-index: 1;
  }

  &:after {
      position: absolute;
      content: "中文";
      color: $secondary-color;
      width: 3rem;
      height: 2rem;
      right: 0rem;
      bottom: 0rem;
      padding-top: 0.1rem;
  }
}

.cnLang {
  & .slider-lang:before {
      transform: translateX(2.5rem);
      content: "中文";
      color: $secondary-color;
      left: -0.01rem;
  }

  & .slider-lang:after {
      transform: translateX(-2.5rem);
      content: "EN";
      color: white;
  }
}
}

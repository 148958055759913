.pdpa {
  cursor: pointer;
} 

.modal {
  // .modal-header {
  //   padding: 0rem;
  //   border-bottom: none;
  // }
  h3, h5 {
    color: $secondary-color;
  }
}